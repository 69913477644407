import firebase from 'firebase';
import { Logger } from 'react-logger-lib';

const firebaseConfig = {
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
  databaseURL: process.env.REACT_APP_FIREBASE_AUTH_DBURL,
  projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
  storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
  messagingSender: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_FIREBASE_APP_ID, 
  measurementId: process.env.REACT_APP_FIREBASE_MEASUREMENT_ID
};

class Firebase {
  constructor() {
    var app;
    if (!firebase.apps.length) { 
      app = firebase.initializeApp(firebaseConfig)
    } else { 
      app =firebase.app()
    }

    if (process.env.REACT_APP_USE_FIREBASE_EMULATOR === 'true') {
      firebase.auth().useEmulator('http://localhost:9099/');
      Logger.of('services/Firebase').info('Using Local Emulator');
    }

    this.auth = app.auth();
  }

  signInWithEmailAndPassword = (email, password) =>
    this.auth.signInWithEmailAndPassword(email, password);

  signOut = () => this.auth.signOut();
}
 
export default Firebase;