import {admin_action_types} from '../actions/admin-actions.js';

const initialState = {
  importData: [],
  importInProgress: false,
  importResult: null, //TODO: rethink this..  better way to encapsulte results
  importErrors: null
};

function admin(state = initialState, action) {
  switch (action.type) {
    case admin_action_types.IMPORT_STARTED:
      return { ...state, importInProgress: true }
    case admin_action_types.IMPORT_SUCCESS:
      return { ...state, importInProgress: false, importResult: action.result }
    case admin_action_types.IMPORT_FAILURE:
      return { ...state, importInProgress: false, importError: action.error }
    default:
      return state;
  }
};

export default admin;