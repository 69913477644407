import { combineReducers } from 'redux';
import admin from './admin-reducer';
import auth from './auth-reducer';
import stashcard from './stashcard-reducer';

const rootReducer = combineReducers({
  admin,
  auth,
  stashcard
});

export default rootReducer;