import React, { Component } from "react";
import { connect } from "react-redux";
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/styles';

import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';

import DataService from '../../services/data-service';

const mapStateToProps = state => {
  return { 
    currentUser: state.auth.currentUser
  };
};

function mapDispatchToProps(dispatch) {
  return {
    
  };
}

const styles = theme => ({
  root: {
    width: '100%',
    marginTop: theme.spacing(3),
    overflowX: 'auto',
    justifyContent: 'center'
  },
  paragraphLeftFloat: {
    float: "left", 
    margin: "10px",
  }
});

class LogMeInPage extends React.Component {
  
  state = {
    
  }

  componentDidMount() {
    
  }


  render() {
    const { classes } = this.props;

    return (
      <div style={{overflowX: 'auto', justifyContent: 'center'}}>
        <Paper className={classes.root}>
        <h1>STASH Magic Link</h1>
        <div className={classes.paragraphLeftFloat} ><img src="/images/scientist.jpg" style={{"width": "100px"}} alt="scientist" /></div>
          <Typography variant="body1" color="textSecondary" align="left">
          Greetings!  You've arrived here using a <i>STASH magic link</i> or <i>Invite</i>, I presume?
          </Typography>
          <Typography variant="body1" color="textSecondary" align="left">
          The link is intended to be followed on an iOS device.  When the <b>STASH app</b> is installed, the link will launch the STASH iOS app and process the encoded URL.  Then, <i>as if by magic</i>, you'll be logged in!  
          </Typography>
          <Typography variant="body1" color="textPrimary" align="left">
          Since you've landed here, I imagine STASH is not installed, yet. <small>STASH is not yet available in the App Store.  We appreciate your generosity in helping us make STASH amazing.</small>
          </Typography>
          <Typography variant="body1" color="textPrimary" align="left">
          
            <ul>
              <li>To install, during our beta program, <a href="https://apps.apple.com/us/app/testflight/id899247664">use Test Flight</a></li>
              <li>After you've installed the <b>Apple's Test Flight app</b>, check your invite text and tap on the second link to Test Flight.  This will launch the <i>Test Flight app</i> and tell TestFlight you are part of the STASH Beta program.  From there, you can install the <i>STASH app</i>.
              </li>
              <li>After you've installed the <b>STASH app</b>, check your invite text and tap on the <i>first link</i>.  This will launch the <i>STASH app</i> and log you in.  
              </li>
            </ul>
          </Typography>
          <Typography variant="body1" color="textSecondary" align="left">
            Once you have the TestFlight and STASH app installed, you won't have to repeat all these steps.  If you find yourself having to log in again or receive a new invitation message from STASH, just follow the invite link and STASH will handle the rest.
          </Typography>
        </Paper>
      </div>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(LogMeInPage));
