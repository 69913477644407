import AuthService from '../services/auth-service';

export const auth_action_types = {
  LOGIN_USER: 'LOGIN_USER',
  LOGIN_FAILED: 'LOGIN_FAILED',
  LOGOUT: 'LOGOUT'
};

export const logout = () => {
  new AuthService().logout()
  return (dispatch, getState) => {
    const user = getState().auth.currentUser;
    const authService = new AuthService();
    authService.logout(user)
      .then(resp => {
         dispatch({ type: auth_action_types.LOGOUT })
      })
    }
}

export const login = authInfo => {
  return dispatch => {
    const authService = new AuthService();
    authService.login(authInfo)
      .then(resp => {
        dispatch(userLoggedIn(resp.user))
      })
      .catch(err => {
        dispatch(loginFailure(err));
      });   
  }
}

const userLoggedIn = userInfo => ({
    type: auth_action_types.LOGIN_USER,
    user: userInfo
})

const loginFailure = error => ({
  type: auth_action_types.LOGIN_FAILED,
  error
})