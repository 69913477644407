import React, { Component } from "react";
import { connect } from "react-redux";
// import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/styles';

import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';

import Button from '@material-ui/core/Button';
import GetAppIcon from '@material-ui/icons/GetApp';

const mapStateToProps = state => {
  return { 
    currentUser: state.auth.currentUser
  };
};

function mapDispatchToProps(dispatch) {
  return {
    
  };
}

const styles = theme => ({
  root: {
    width: '100%',
    marginTop: theme.spacing(3),
    overflowX: 'auto',
    justifyContent: 'center'
  }
});

class GroupPage extends Component {
  
  state = {
    loading: false,
    group: null,
    error: null
  }

  fetchData() {
    // Here is where we'd load the group to display the name
  }

  componentDidMount() {
    this.fetchData();
  }


  render() {
    const { classes } = this.props;

    let groupContent;
    if (this.state.group) {
      groupContent =  <div>
                          <h2>{this.state.group.name}</h2>
                          <p>It looks like someone invited you to the group <b>{this.state.group}.</b></p>
                      </div>;
    } else {
      groupContent =  <div>
                          <p>It looks like someone invited you to a friend group on STASH.</p>
                      </div>;
    }

    return (
      <div style={{overflowX: 'auto', justifyContent: 'center'}}>
        
        <Paper className={classes.root}>
            <h1>STASH Friend Group</h1>
            <img src="/stash-screens-1.jpg" style={{float: 'left', border: '1px solid #CCF0FF', margin: '10px'}} />
            
            <Typography variant="body1" color="textPrimary" align="center">
    
                <h3 className={classes.header2}>Welcome to STASH!</h3>
                {this.state.loading && 'Fetching StashCard...' }
                {this.state.error && 'ERROR' }
                {groupContent}
                <div style={{padding: '20px'}}>
                    <p>To see the full details, please</p>
                    <Button className={classes.installButton} size="large" variant="contained" color="primary" endIcon={<GetAppIcon />} target="_blank" href="https://apps.apple.com/us/app/stash-social/id1595856058">
                        Install the STASH App
                    </Button>
                </div>
            </Typography>
        </Paper>
      </div>
    );
  }
}
const GroupPageWithStyles = withStyles(styles)(GroupPage)
export default connect(mapStateToProps, mapDispatchToProps)(GroupPageWithStyles);
