
import { createStore, applyMiddleware } from "redux";
import { createLogger } from 'redux-logger';
import thunk from 'redux-thunk';
import rootReducer from "../reducers/index";
import {loadState, saveState} from '../services/local-storage';
import {throttle} from 'lodash/throttle';

const persistedState = loadState();
const store = createStore(
    rootReducer,
    persistedState,
    applyMiddleware(
      createLogger(), // neat middleware that logs actions
      thunk
    )
  );

  const saveStateWrapper = () => { 
    // clear auth state prior to save
    saveState({...store.getState(), admin: {importData: [], importInProgress: false, importResult: null, importErrors: null}}); 
  };
  // tried to wrap in throttle from lodash but didn't work.
  store.subscribe(saveStateWrapper);

export default store;