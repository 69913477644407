import React, { Component, Link } from "react";
import { connect } from "react-redux";
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/styles';

import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';
import Button from '@material-ui/core/Button';
import GetAppIcon from '@material-ui/icons/GetApp';
import DeleteIcon from '@material-ui/icons/Delete';

import DataService from '../../services/data-service';

const mapStateToProps = state => {
  return { 
    currentUser: state.auth.currentUser
  };
};

function mapDispatchToProps(dispatch) {
  return {
    
  };
}

const styles = theme => ({
  root: {
    width: '100%',
    marginTop: theme.spacing(3),
    overflowX: 'auto',
    justifyContent: 'center'
  },
  header2: { color: '#888888'},
  devices: {
    width: '400px',
    float: 'right',
  },
  installButton: {
    margin: '10px'
  },
  getAppIcon: {
    marginLeft: '10px'
  }
});

class VendorPage extends React.Component {
  
  state = {
    loading: false,
    stashCard: null,
    error: null
  }

  load() {
    const dataService = new DataService();
    this.setState({loading: true});
    dataService.getVendor(this.props.id)
    .then(vendor => {
      this.setState({vendor: vendor, loading: false});
    })
    .catch(error => {
      this.setState({error: error, loading: false});
    })
  }

  componentDidMount() {
    this.load();
  }

  render() {
    const { classes } = this.props;

    return (
      <div style={{overflowX: 'auto', justifyContent: 'center'}}>
        <Paper className={classes.root}>
        <h1 className={classes.header2}>STASH Vendor Share</h1>
          <img src="/images/devices.png" alt="STASH app on devices" className={classes.devices}/>
          <Typography variant="body1" color="textPrimary" align="center">
          {this.state.loading && 'Fetching StashCard...' }
          {this.state.error && 'ERROR' }
          {this.state.vendor && (
            <div>
              <h3 className={classes.header2}>Welcome to STASH!</h3>
              <h2>{this.state.vendor.companyName}</h2>
              <p>It looks like someone shared <b>{this.state.vendor.companyName}</b> with you</p>
              <p>To see the full details, please</p>
              <Button className={classes.installButton} size="large" variant="contained" color="primary" endIcon={<GetAppIcon />} target="_blank" href="https://apps.apple.com/us/app/stash-social/id1595856058">
                Install the STASH App
              </Button>
            </div>
          )}
          </Typography>
        </Paper>
      </div>
    );
  }
}
const VendorPageWithStyles = withStyles(styles)(VendorPage)
export default connect(mapStateToProps, mapDispatchToProps)(VendorPageWithStyles);
