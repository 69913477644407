import PhoneNumber from 'awesome-phonenumber';
import toTitleCase from 'titlecase';
import ParseAddress from 'parse-address';
import DataService from './data-service';
import moment from 'moment';

const NotImplementedError = class NotImplementedError extends Error {};

const csv_import_column_names = {
  COMPANY_NAME: 'Company',
  STASHER:	'Stasher',
  STASHERS_USING:	'Stasher\'s Using',
  CATEGORY:	'CATEGORY',
  SUB_CATEGORY: 'Sub-Category',
  CONTACT_NAME: 'Contact Name',
  BUSINESS_PHONE: 'Business Phone',
  WEBSITE_URL: 'Website',
  BUSINESS_EMAIL: 'Business Email',
  ADDRESS_STREET: 'Address Street',
  ADDRESS_CITY: 'Address City',
  ADDRESS_STATE_CODE: 'State Code',
  ADDRESS_ZIP: 'Address Zip',
  NOTES: 'Notes'
}

class ImportDataManager {

  _toTitleCase(str){
    return str ? toTitleCase(str.trim().toLowerCase()) : null;
  }

  _valueOrNullIfEmptyForKey(key, hash){
    const str = hash[key];
    return (str && str.length > 0) ? str.trim() : null;
  }

  // super simple email format validation
  _emailIsValid (email) {
    return /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email)
  }

  extractStashers(importData){
    // map originating stasher
    var allStashers = importData
      .map(item => this._valueOrNullIfEmptyForKey(csv_import_column_names.STASHER, item))
    
    // map stashers using (comma delimited)
    importData.map(item => this._valueOrNullIfEmptyForKey(csv_import_column_names.STASHERS_USING, item))
      .forEach(fieldValue => { // iterate results
        if(fieldValue) {
          fieldValue.split(',') // split on comma
            .forEach(stasherName => { // iterate each stasher name
              if(stasherName.trim().length > 0) {
                allStashers.push(stasherName.trim())
              }
            })
        }
          
      })
      
      return allStashers.filter((item, index, array) => item && item.length > 0 && array.indexOf(item) === index) // dedup
      .map(name => { // split name parts
        const nameParts = name.trim().split(' ');
        return {
          firstName: nameParts[0],
          lastName: nameParts.length > 1 ? nameParts[1] : null
        }
      });
  }

  extractCategories(importData){
    return importData
      .map(item => this._toTitleCase(this._valueOrNullIfEmptyForKey(csv_import_column_names.CATEGORY, item)))
      .filter((item, index, array) => item && item.length > 0 && array.indexOf(item) === index)
      .map(item => {return {name: item}});
  }

  extractSubCategories(importData){
    return importData
      .map(item => {
        return JSON.stringify({
          name: this._toTitleCase(this._valueOrNullIfEmptyForKey(csv_import_column_names.SUB_CATEGORY, item)),
          parentName: this._toTitleCase(this._valueOrNullIfEmptyForKey(csv_import_column_names.CATEGORY, item))
        })
      })
      .filter((item, index, array) => item && array.indexOf(item) === index)
      .map(JSON.parse)
      .filter(item => item.name); //ensure it's not a null sub-cat (no, that should not happen.  But it did)
  }

  extractVendors(importData){
    const cicn = csv_import_column_names;
    let timeStampString = moment.utc().format();
    let sourceOfRecordName = `CSV Import ${timeStampString}`;
    // map to more useable property names
    return importData.map(item => {
      
      // normalize business phone number
      let businessPhone = null;
      const phoneString = this._valueOrNullIfEmptyForKey(cicn.BUSINESS_PHONE, item);
      // esure there's a value
      if(phoneString) {
        // parse the phone number string
        const pn = new PhoneNumber(phoneString, 'US');
        if(pn.isValid()){
          businessPhone = pn.getNumber( 'national' ); // 'national' example: (404) 875-0075
        }else{
          console.log('business phone "' + phoneString + '" invalid for vendor ' +  item[cicn.COMPANY_NAME] + ' ' + item[cicn.CONTACT_NAME]);
        } 
      }

      let address = {
        street: this._valueOrNullIfEmptyForKey(cicn.ADDRESS_STREET, item),
        city: this._valueOrNullIfEmptyForKey(cicn.ADDRESS_CITY, item),
        stateCode: this._valueOrNullIfEmptyForKey(cicn.ADDRESS_STATE_CODE, item),
        postalCode: this._valueOrNullIfEmptyForKey(cicn.ADDRESS_ZIP, item)
      };

      if(!address.postalCode) {
        const parsedAddress = ParseAddress.parseLocation(address.street);
        /* //Parsed address: '1005 N Gravenstein Hwy Suite 500 Sebastopol, CA'
          { number: '1005',
            prefix: 'N',
            street: 'Gravenstein',
            type: 'Hwy',
            sec_unit_type: 'Suite',
            sec_unit_num: '500',
            city: 'Sebastopol',
            state: 'CA'
          } */
        const street = parsedAddress.prefix ? `${parsedAddress.number} ${parsedAddress.prefix} ${parsedAddress.street} ${parsedAddress.type}` : `${parsedAddress.number} ${parsedAddress.street} ${parsedAddress.type}`
        
        if(parsedAddress.zip){
          address = {
            street      : street,
            street2     : parsedAddress.sec_unit_num ? `${parsedAddress.sec_unit_type} ${parsedAddress.sec_unit_num}` : null,
            city        : parsedAddress.city,
            stateCode   : parsedAddress.state,
            postalCode  : parsedAddress.zip
          };
        }
      }

      if(!address.street || !address.postalCode) {
        // not a valid address
        address = null;
      }
      
      return {
        companyName: this._valueOrNullIfEmptyForKey(cicn.COMPANY_NAME, item),
        stasherName: this._valueOrNullIfEmptyForKey(cicn.STASHER, item),
        stashersUsing: this._valueOrNullIfEmptyForKey(cicn.STASHERS_USING, item),
        categoryName: this._toTitleCase(this._valueOrNullIfEmptyForKey(cicn.CATEGORY, item)),
        subCategoryName: this._toTitleCase(this._valueOrNullIfEmptyForKey(cicn.SUB_CATEGORY, item)),
        contactName: this._valueOrNullIfEmptyForKey(cicn.CONTACT_NAME, item),
        businessPhone: businessPhone,
        websiteUrl: this._valueOrNullIfEmptyForKey(cicn.WEBSITE_URL, item),
        businessEmail: this._valueOrNullIfEmptyForKey(cicn.BUSINESS_EMAIL, item),
        address: address,
        notes: this._valueOrNullIfEmptyForKey(cicn.NOTES, item),
        sourceOfRecordName: sourceOfRecordName
      }
    })
    .filter(vendor => vendor.companyName || vendor.contactName); // has to have at least a name
  }

  
  
  dedupVendorsWithMerge(vendors) {
    // an address is considered a match if one or more are null
    // a null address will match with the first matching name that precedes it
    // last record in wins if field conflict occurs
    const vendorsMatch = (vendor1, vendor2) => {
      const sameName = (vendor1.companyName != null && vendor1.companyName === vendor2.companyName) || (vendor1.contactName != null && vendor1.contactName === vendor2.contactName);
      const sameAddress = (!vendor1.address || !vendor2.address) || 
        ( 
          (vendor1.address.street === vendor2.address.street) &&
          (vendor1.address.postalCode === vendor2.address.postalCode)
        );
      return sameName && sameAddress
    }

    const mergePreferValueToNull = (obj1, obj2) => {
      // copy obj1
      var answer = {...{},...obj1}
      // copy 1nd object first
      for(var key in obj2) {
        if(obj2[key] !== null) {
          answer[key] = obj2[key];
        }
      }

      return answer
    }

    return vendors.reduce((deduped, vendor) => {
      const idx = deduped.findIndex(item => vendorsMatch(item, vendor));
      if(idx > -1){
        // merge the two matched vendor records and replace at index
        // aggregate stashersUsing
        var stashersUsing = '';
        if(deduped[idx].stashersUsing) stashersUsing = deduped[idx].stashersUsing;
        if(vendor.stashersUsing){
          if(stashersUsing.length > 0) stashersUsing += ', '
          stashersUsing += vendor.stashersUsing
        }
        deduped[idx] = mergePreferValueToNull(deduped[idx], vendor);
        deduped[idx].stashersUsing = stashersUsing;
        
        return deduped;
      } else {
        // no match, append
        return [...deduped, vendor];
      }
    }, []);
  }

  cleanStashersWithReport(importData) {
    let report = [];
    const notEmptyVal = str => {
      return (str && str.trim().length > 0);
    }
    const importDataSansEmpties = importData.filter(item => {
      return notEmptyVal(item.firstName) ||
        notEmptyVal(item.lastName) ||
        notEmptyVal(item.email) ||
        notEmptyVal(item.mobilePhone);
    });

    const stashers = importDataSansEmpties.map(item => {
      let mobilePhone = null;
      let email = null;
      let itemReport = '';
      // validate phone number
      if(item.mobilePhone) {
        const pn = new PhoneNumber(item.mobilePhone, 'US');
        if(pn.isValid()){
          mobilePhone = pn.getNumber( 'national' ); // 'national' example: (404) 875-0075
        }else{
          const reportLine = `mobilePhone (${item.mobilePhone}) invalid for ${item.firstName} ${item.lastName}`;
          console.log(reportLine);
          report.push(reportLine);
        } 
      }

      // validate email
      if(item.email) {
        const trimmedEmail = item.email.trim();
        if(trimmedEmail.length > 0) {
          if(this._emailIsValid(trimmedEmail)) {
            email = trimmedEmail;
          } else {
            const reportLine = `email (${trimmedEmail}) invalid for ${item.firstName} ${item.lastName}`;
            console.log(reportLine);
            report.push(reportLine);
          }
        }
      }

      if(!(email || mobilePhone)) {
        // if no values, just skip the line.  It's an empty line
        if((item.firstName || item.lastName)) {
          const reportLine = `both email and mobile missing for ${item.firstName} ${item.lastName}`;
          console.log(reportLine);
          report.push(reportLine);
        }
      }

      let stasher = {
        firstName: this._toTitleCase(item.firstName),
        lastName: this._toTitleCase(item.lastName),
        email,
        mobilePhone
      };
      return stasher;
    });

    return {stashers, report};
  }

  publishImportStashersData(importData) {
    const dataService = new DataService();
    const {stashers, report} = this.cleanStashersWithReport(importData);
    return dataService.getStashers()
    .then(existingStashers => {

      let updateStashers = [];
      let insertStashers = [];

      stashers.forEach(importStasher => {
        const match = existingStashers.find(existingStasher => {
          // match on email or mobile phone or (first and last name)
          return (
            (importStasher.email && existingStasher.email && importStasher.email.toLowerCase() === existingStasher.email.toLowerCase()) ||
            (importStasher.mobilePhone && importStasher.mobilePhone === existingStasher.mobilePhone) ||
            (importStasher.firstName && importStasher.lastName && importStasher.firstName === existingStasher.firstName && importStasher.lastName === existingStasher.lastName) 
          );
        }); // end find
        if(match) {
          // match.. update using existing id
          updateStashers.push({...importStasher, id: match.id});
        } else {
          insertStashers.push(importStasher);
        }
      }); // end forEach

      const allUpdates = updateStashers.map(stasher => {return dataService.updateStasher(stasher)});
      return Promise.all(allUpdates)
      .then(updates => {
        return Promise.all([dataService.saveNewStashers(insertStashers), updates]);
      })
      .then(([newStashers, updates]) => {
        const importReport = {
          insertCount: newStashers.length,
          updateCount: updates.length,
          issues: report
        };
        return importReport;
      });
    });
  }


  publishImportData(importData) {

    return new Promise((resolve, reject) => { 
      console.log('In publishImportData promise body');
      const dataService = new DataService();
      
      // extract distinct Stashers, Categories, Sub-Categories, Vendors
      const categories = this.extractCategories(importData);
      const subCategories = this.extractSubCategories(importData);
      const stashers = this.extractStashers(importData);
      const vendors = this.dedupVendorsWithMerge(this.extractVendors(importData));

      // look for existing category matches based on name
      const categoryNames = categories.map(cat => cat.name);
      Promise.all([
        dataService.getCategories(categoryNames),
        dataService.getStashers()
      ])
        .then(([categoryMatches, allStashers]) => {
          // filter new categories where no match was found
          const newCategories = categories.filter(cat => categoryMatches.findIndex(m => m.name === cat.name) < 0);
          const newStashers = stashers.filter(stasher => allStashers.findIndex(m => (m.email && m.email === stasher.email) || (m.firstName === stasher.firstName && m.lastName === stasher.lastName)) < 0);
          // save the new categories and stashers
          return Promise.all([
            dataService.saveCategories(newCategories), // save new top level categories
            dataService.saveNewStashers(newStashers), // save new top level categories
            categoryMatches, // pass existing categories through,
            allStashers // pass existing stashers through
          ]);
        })
        .then(([newCategories, newStashers, currentCategories, currentStashers]) => {
          // merge current categories with new and place in state object
          let importState = {
            categories: [...currentCategories, ...newCategories],
            stashers: [...currentStashers, ...newStashers]
          } 
          // find sub categories already present matched on name
          // note: this does not consider parent category and probably should
          const subCatNames = subCategories.map(cat => cat.name);
          return Promise.all([
            dataService.getCategories(subCatNames),
            importState
          ]);
        })
        .then(([subCategoryMatches, importState]) => {
          // save matches to state
          importState.subCategories = subCategoryMatches;
          
          // filter out new imported sub cats
          const newSubCategories = subCategories.filter(cat => subCategoryMatches.findIndex(m => m.name === cat.name) < 0);
          // associate parent category with sub category
          const subCats = newSubCategories
            .map(subCat => {
              const parent = importState.categories.find(cat => cat.name === subCat.parentName);
              if(!parent) {
                console.log('failed to find parent category named "' + subCat.parentName + '" for sub-category "' + subCat.name +'"' );
                return {
                  name: subCat.name
                }
              }
              
              return {
                name: subCat.name,
                parentCategoryId: parent.id
              }
            });
            
            // save sub categories
            return Promise.all([
              dataService.saveCategories(subCats), // save sub categories
              importState // pass import state through
            ]);
        }) 
        .then(([newSubCategories, importState]) => { 
          // merge categories with new and existing sub categories
          importState.categories = [...importState.categories, ...importState.subCategories, ...newSubCategories];
          // all done with sub categories as a separate collection
          delete importState.subCategories;

          //TODO: query for matching vendors and map import to existing
          // assign sub categories and stasher to vendor
          const assignedVendors = vendors
            .map(vendor => {
              let vendorCopy = {...vendor}
              // find category id by category name
              let categoryIds = [], categoryName;
              try{
                // use sub-category unless empty, fall back to category
                categoryName = vendor.subCategoryName || vendor.categoryName
                const category = importState.categories.filter(cat => cat.name === categoryName)[0];
                categoryIds.push(category.id);
                // assign categories as 
                vendorCopy.categoryIds = categoryIds;
              }
              catch(err){
                console.log('failed to find sub-category named "' + categoryName + '" for vendor "' + vendor.companyName +'"', err );
              }
              
              // method to match concatenated name with first/last
              const fullNameMatchesStasherName = (fullName, stasher) => {
                if(stasher.lastName){
                  return fullName === stasher.firstName + ' ' + stasher.lastName;
                }
                return fullName === stasher.firstName;
              };

              vendorCopy.stasherIds = [];

              // find stasher matched on full name
              let stasher = importState.stashers.find(stasher => fullNameMatchesStasherName(vendor.stasherName, stasher));
              if(stasher){
                vendorCopy.originalStasherId = stasher.id;
                vendorCopy.stasherIds.push(stasher.id);
              } else {
                console.log('failed to find stasher named "' + vendor.stasherName + '" for vendor "' + vendor.companyName +'"' );
              }

              if(vendor.stashersUsing) {
                vendor.stashersUsing.split(',')
                  .forEach(stasherName => {
                    if(stasherName.trim().length > 0) {
                      let stasher = importState.stashers.find(stasher => fullNameMatchesStasherName(stasherName.trim(), stasher));
                      if(stasher){
                        vendorCopy.stasherIds.push(stasher.id);
                      } else {
                        console.log('failed to find stasher using named "' + stasherName + '" for vendor "' + vendor.companyName +'"' );
                      }
                    }
                  })
              }

              // dedup
              vendorCopy.stasherIds = vendorCopy.stasherIds.filter((item, index, array) => item && array.indexOf(item) === index) // dedup
      
              // delete unused fields
              delete vendorCopy.subCategoryName;
              delete vendorCopy.categoryName;
              delete vendorCopy.stasherName;
              delete vendorCopy.stashersUsing;
              return vendorCopy;
            })

            // we save stashcards here as part of the vendor save.  
            // save vendor
            return Promise.all([
              dataService.saveVendors(assignedVendors),
              importState
            ]);
        }).then(([vendors, importState]) => {
          importState.vendors = vendors;

          let stashCards = vendors.reduce((accumulator, vendor) => {
            let vendorStashCards = [];
            if(!vendor.stashcards){
              console.log('WARNING: ' + vendor.companyName + ' has no stashCards');
            } else {
              vendorStashCards = vendor.stashcards.map(stashCard => {
                return {
                  id: stashCard.id,
                  stasherId: stashCard.stasher.id,
                  vendorId: vendor.id
                }
              });
            }
            return [...accumulator, ...vendorStashCards];
          }, []);
          
          resolve({
            categories: importState.categories,
            stashers: importState.stashers,
            vendors: importState.vendors,
            stashCards
          });  
        })
        .catch(reject)

      // above vendors contain dups with different stashers
      // need to next resolve categories, sub-catefgories 
      //   and stashers with remote repos and then replace names 
      //   with one2many relationships

      
        
      
      // query for existing Stasher.firstName+lastName, Category.title, Vendor.phone||email
      // merge matches.  Then query for sub-categories having same title and parent 
      // merge matches.
      // For merges, use data from import, keep id from repos
      // post updates and inserts
      // collect results
      // dispatch CSV_IMPORT_RECEIVED
    });
  }
}

export default ImportDataManager;