import React from 'react';
import { Router, Link } from "@reach/router";
import { connect } from "react-redux";
import PropTypes from 'prop-types';
import CssBaseline from '@material-ui/core/CssBaseline';
import Divider from '@material-ui/core/Divider';
import Drawer from '@material-ui/core/Drawer';
import Hidden from '@material-ui/core/Hidden';

import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';

import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';

import LoginIcon from '@material-ui/icons/Security';
import ImportIcon from '@material-ui/icons/SystemUpdateAlt';
import MenuItemIcon from '@material-ui/icons/TagFaces';

import Typography from '@material-ui/core/Typography';
import { makeStyles, useTheme } from '@material-ui/core/styles';

import { logout } from "../../actions/index";

const drawerWidth = 240;

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
  },
  drawer: {
    [theme.breakpoints.up('sm')]: {
      width: drawerWidth,
      flexShrink: 0,
    },
  },
  appBar: {
    [theme.breakpoints.up('sm')]: {
      width: `calc(100% - ${drawerWidth}px)`,
      marginLeft: drawerWidth,
    },
  },
  menuButton: {
    marginRight: theme.spacing(2),
    [theme.breakpoints.up('sm')]: {
      display: 'none',
    },
  },
  toolbar: theme.mixins.toolbar,
  drawerPaper: {
    width: drawerWidth,
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
  },
}));

const mapStateToProps = state => {
  return { 
    currentUser: state.auth.currentUser
  };
};

function mapDispatchToProps(dispatch) {
  return {
    logout: () => dispatch(logout())
  };
}

function NavMenu(props) {
  const { container } = props;
  const classes = useStyles();
  const theme = useTheme();

  const handleDrawerToggle = () => {
    props.handleDrawerToggle()
  };

  const handleLogout = () => {
    props.logout();
  };


  const drawer = (
    <div>
      <div className={classes.toolbar} />
      <Divider />
      <List>
        {props.currentUser && (
          <ListItem button key="Log In" onClick={handleLogout}>
            <ListItemIcon><LoginIcon /></ListItemIcon>
            <ListItemText primary="Log out" />
          </ListItem>
        )}
        {!props.currentUser && (
          <ListItem button component={Link} key="Log In" to="/admin/login">
            <ListItemIcon><LoginIcon /></ListItemIcon>
            <ListItemText primary="Log in" />
          </ListItem>
        )}
        
      </List>
      <Divider />
      <List>
        <ListItem button component={Link} key="Test" to="/admin/test">
          <ListItemIcon><MenuItemIcon /></ListItemIcon>
          <ListItemText primary="Test" />
        </ListItem>
        <ListItem button component={Link} key="Import StashCards" to="/admin/csv-import-stashcards">
          <ListItemIcon><ImportIcon /></ListItemIcon>
          <ListItemText primary="Import StashCards" />
        </ListItem>
        <ListItem button component={Link} key="Import Stashers" to="/admin/csv-import-stashers">
          <ListItemIcon><ImportIcon /></ListItemIcon>
          <ListItemText primary="Import Stashers" />
        </ListItem>
      </List>
    </div>
  );
  
  return (
    
      <nav className={classes.drawer} aria-label="mailbox folders">
        {/* The implementation can be swapped with js to avoid SEO duplication of links. */}
        <Hidden smUp implementation="css">
          <Drawer
            container={container}
            variant="temporary"
            anchor={theme.direction === 'rtl' ? 'right' : 'left'}
            open={props.openDrawer}
            onClose={handleDrawerToggle}
            classes={{
              paper: classes.drawerPaper,
            }}
            ModalProps={{
              keepMounted: true, // Better open performance on mobile.
            }}
          >
            {drawer}
          </Drawer>
        </Hidden>
        <Hidden xsDown implementation="css">
          <Drawer
            classes={{
              paper: classes.drawerPaper,
            }}
            variant="permanent"
            open
          >
            {drawer}
          </Drawer>
        </Hidden>
      </nav>
      

  );
}

NavMenu.propTypes = {
  
};

export default connect(mapStateToProps, mapDispatchToProps)(NavMenu);