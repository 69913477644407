import Firebase from './firebase';
import {saveState} from './local-storage';

class AuthService  {

  constructor() {
    this.endPointURL = '/api/auth/';
  }

  logout(userInfo){
    return new Promise((resolve, reject) => {
      // clear state
      saveState({});
      return resolve();
    });
  }

  login(authInfo) {
    const firebase = new Firebase();
    const email = authInfo.username;
    const pw = authInfo.password;
    
    return firebase.signInWithEmailAndPassword(email, pw)
    .then((userCredential) => {
      // Signed in
      const user = userCredential.user;
      return {
        user: {
          userId: user.uid,
          avatar: user.photoURL,
          name: user.displayName || user.email || "Stasher"
        }
      };

    })
    .catch((error) => {
      throw error
      // var errorCode = error.code;
      // var errorMessage = error.message;
    });
  }
}

export default AuthService;