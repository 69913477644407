import ImportDataManager from '../services/import-data-manager';

export const admin_action_types = {
  IMPORT_STARTED: 'IMPORT_STARTED',
  IMPORT_SUCCESS: 'IMPORT_SUCCESS',
  IMPORT_FAILURE: 'IMPORT_FAILURE'
};

const importStarted = () => ({
  type: admin_action_types.IMPORT_STARTED
});

const importSuccess = (res) => ({
  type: admin_action_types.IMPORT_SUCCESS,
  result: res
});

const importFailure = (error) => ({
  type: admin_action_types.IMPORT_FAILURE,
  error
});

