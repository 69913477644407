import {stashcard_action_types} from '../actions/stashcard-actions.js';

const initialState = {
  categories: [],
  stashcards: []
};

function stashcard(state = initialState, action) {
  switch (action.type) {
    case stashcard_action_types.SAVE_CATEGORIES_SUCCESS:
      console.log('SAVE_CATEGORIES_SUCCESS!  Now what?')
      return state
    default:
      return state;
  }
};

export default stashcard;