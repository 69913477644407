import React, { Component } from "react";
import { connect } from "react-redux";


import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';

const mapStateToProps = state => {
  return { 
    

  };
};

function mapDispatchToProps(dispatch) {
  return {
    
  };
}

class ConnectedTestPage extends Component {
  constructor(props) {
    super(props);
    
    this.state = {
      
    };
  }

  
  
  render() {
    //TODO: figure out why this shit don't work. 
    // Can't get center or margin to work no matter what I do
    const classes = makeStyles(theme => ({
      root: {
        width: '100%',
        marginTop: theme.spacing(3),
        overflowX: 'auto',
        justifyContent: 'center',
        border: 'solid 5px yellow'
      },
      button: {
        margin: theme.spacing(1),
      },
      modal: {
        position: 'absolute',
        top: '50%',
        left: '50%',
        width: 400,
        backgroundColor: 'white', //theme.palette.background.paper,
        border: '2px solid #000',
        boxShadow: theme.shadows[5],
        padding: theme.spacing(2, 4, 3)
      }
    }));
    
    return (
      <div style={{overflowX: 'auto', justifyContent: 'center'}}>
        <Paper className={classes.root}>
          <a href="http://app.stash-app.com/ios/log-me-in/a6364a54-5afd-4e80-9105-18faa320837b
">auth link test</a>
        </Paper>
      </div>
    );
  };
}

const TestPage = connect(mapStateToProps, mapDispatchToProps)(ConnectedTestPage);

export default TestPage;