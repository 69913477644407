
import dataService from '../services/data-service';

export const stashcard_action_types = {
  SAVE_CATEGORIES: 'SAVE_CATEGORIES',
  SAVE_CATEGORIES_SUCCESS: 'SAVE_CATEGORIES_SUCCESS',
  SAVE_CATEGORIES_FAILURE: 'SAVE_CATEGORIES_FAILURE',
  SYNC_IN_PROCESS: 'SYNC_IN_PROCESS',
};

export const sync_types = {
  CATEGORIES_SAVE: 'CATEGORIES_SAVE'
}

const saveCategoriesStarted = () => ({
  type: stashcard_action_types.SYNC_IN_PROCESS,
  sync_type: sync_types.CATEGORIES_SAVE
});

const saveCategoriesSuccess = () => ({
  type: stashcard_action_types.SAVE_CATEGORIES_SUCCESS
});

const saveCategoriesFailure = (error) => ({
  type: stashcard_action_types.SAVE_CATEGORIES_FAILURE,
  error
});

/**
 * Save categories to local and remote storage.  
 *  Pass an array of Category
 * 
 * @param {[Category]} categories
 * @public
 */
export function saveCategory(category) {
  return dispatch => {
    dispatch(saveCategoriesStarted());
    dataService.saveCategories([category])
      .then(res => {
        dispatch(saveCategoriesSuccess(res));
      })
      .catch(err => {
        dispatch(saveCategoriesFailure(err));
      });
    }
};