import React, { Component } from "react";
import { connect } from "react-redux";
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/styles';

import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';

import DataService from '../../services/data-service';

const mapStateToProps = state => {
  return { 
    currentUser: state.auth.currentUser
  };
};

function mapDispatchToProps(dispatch) {
  return {
    
  };
}

const styles = theme => ({
  root: {
    width: '100%',
    marginTop: theme.spacing(3),
    overflowX: 'auto',
    justifyContent: 'center'
  }
});

class StashCardPage extends React.Component {
  
  state = {
    loading: false,
    stashCard: null,
    error: null
  }

  fetchStashCard() {
    const dataService = new DataService();
    this.setState({loading: true});
    dataService.getStashCard(this.props.id)
    .then(stashCard => {
      this.setState({stashCard: stashCard, loading: false});
    })
    .catch(error => {
      this.setState({error: error, loading: false});
    })
  }

  componentDidMount() {
    this.fetchStashCard();
  }


  render() {
    const { classes } = this.props;

    if(!this.props.currentUser) {
      return (
        <div>Sorry, you need to be logged in as an admin to access stashcards</div>
      )
    }

    return (
      <div style={{overflowX: 'auto', justifyContent: 'center'}}>
        <Paper className={classes.root}>
        <h1>Stashcard {this.props.id}</h1>
          <Typography variant="body1" color="textPrimary" align="center">
          {this.state.loading && 'Fetching StashCard...' }
          {this.state.error && 'ERROR' }
          {this.state.stashCard && (
            <p>{this.state.stashCard.vendor.companyName}</p>
          )}
          </Typography>
        </Paper>
      </div>
    );
  }
}
const StashCardPageWithStyles = withStyles(styles)(StashCardPage)
export default connect(mapStateToProps, mapDispatchToProps)(StashCardPageWithStyles);
