import {auth_action_types} from '../actions/auth-actions.js';

const initialState = {
  currentUser: null
};

function auth(state = initialState, action) {
  switch (action.type) {
    case auth_action_types.LOGIN_USER:
      return { ...state, currentUser: action.user, loginError: null }
    case auth_action_types.LOGOUT:
      return { ...state, currentUser: undefined }
    case auth_action_types.LOGIN_FAILED:
      return { ...state, loginError: action.error }
    default:
      return state;
  }
};

export default auth;