import React, {Component} from 'react';
import {connect} from 'react-redux';
import {login} from '../../actions/auth-actions';
import './Login.css';

class Login extends Component {
  state = {
    username: "",
    password: ""
  }

  handleChange = event => {
    this.setState({
      [event.target.name]: event.target.value
    });
  }

  handleSubmit = event => {
    event.preventDefault()
    this.props.login(this.state)
  }

  render() {
    if(this.props.currentUser){
      return (
        <div>
          <h2>welcome, {this.props.currentUser.name}!</h2>
          <p>proceed...</p>
        </div>
     
      )
    }
    return (
      <form onSubmit={this.handleSubmit}>
        <h1>Login</h1>
        {this.props.loginError && (
          <p className='errors'>
            Login Failed {this.props.loginError.status}
          </p>
        )}

        <label>Username</label>
        <input
          name='username'
          placeholder='Username'
          value={this.state.username}
          onChange={this.handleChange}
          /><br/>

        <label>Password</label>
        <input
          type='password'
          name='password'
          placeholder='Password'
          value={this.state.password}
          onChange={this.handleChange}
          /><br/>

        <input type='submit' className='login-button' value='Log In'/>
      </form>
    )
  }
}

const mapStateToProps = state => {
  return { 
    currentUser: state.auth.currentUser,
    loginError: state.auth.loginError
  };
};

const mapDispatchToProps = dispatch => ({
  login: userInfo => dispatch(login(userInfo))
})

export default connect(mapStateToProps, mapDispatchToProps)(Login);