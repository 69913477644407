import React, { Component } from 'react';
import { Router, Link } from "@reach/router";

// redux
import { Provider } from "react-redux";
import store from "./store/index";
// mui theme
import { ThemeProvider } from '@material-ui/styles';
import CssBaseline from '@material-ui/core/CssBaseline';
import theme from './theme';
// assets/resources
import logo from './logo.svg';
import './App.css';
// components
import Container from '@material-ui/core/Container';
import AppBar from '@material-ui/core/AppBar';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';

// stash components

import TestPage from './components/TestPage';
import HomePage from './components/HomePage';
import StashCardPage from './components/stashcard/StashCardPage';
import GroupPage from './components/group/GroupPage';
import VendorPage from './components/vendor/VendorPage';
import MagicLinkPage from './components/auth/MagicLinkPage';
import Login from './components/auth/Login';
import NavMenu from './components/navigation/NavMenu';

import { makeStyles, useTheme } from '@material-ui/core/styles';

const drawerWidth = 240;

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
  },
  drawer: {
    [theme.breakpoints.up('sm')]: {
      width: drawerWidth,
      flexShrink: 0,
    },
  },
  appBar: {
    [theme.breakpoints.up('sm')]: {
      width: `calc(100% - ${drawerWidth}px)`,
      marginLeft: drawerWidth,
    },
  },
  landingAppBar: {
    [theme.breakpoints.up('sm')]: {
    },
  },
  stashLogo: {
    paddingLeft: theme.spacing(1),
    paddingTop: theme.spacing(1),
  },
  menuButton: {
    marginRight: theme.spacing(2),
    [theme.breakpoints.up('sm')]: {
      display: 'none',
    },
  },
  toolbar: theme.mixins.toolbar,
  drawerPaper: {
    width: drawerWidth,
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
  },
}));

function App(props) {
  const classes = useStyles();
  const theme = useTheme();
  const [drawerOpen, setDrawerOpen] = React.useState(false);

  const handleDrawerToggle = () => {
    console.log('drawerOpen' + drawerOpen);
    setDrawerOpen(!drawerOpen);
  };

  const Admin = ({ children }) => (
    <div className={classes.root}>
      <AppBar position="fixed" className={classes.appBar}>
        <Toolbar>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            edge="start"
            onClick={handleDrawerToggle}
            className={classes.menuButton}
          >
            <MenuIcon />
          </IconButton>
          <header className="App-header">
            <img src={logo} className="App-logo" alt="logo" />
            <span style={{marginLeft:'20px'}}>STASH admin</span>
          </header>
        </Toolbar>
      </AppBar>
  
      <NavMenu handleDrawerToggle={handleDrawerToggle} openDrawer={drawerOpen}/>
      <main className={classes.content}>
        <div className={classes.toolbar} />
        <Container>
          <Box my={4}>
            {children}
          </Box>  
        </Container>
      
        <Copyright />
      </main>
    </div>
  )

  const Landing = ({ children }) => (
    <div className={classes.root}>
      <AppBar position="fixed" className={classes.landingAppBar}>
        <header className="App-header">
          <img src="/stash-logo.png" alt="Stash" className={classes.stashLogo}/>
        </header>
      </AppBar>
      <main className={classes.content}>
        <div className={classes.toolbar} />
        <Container>
          <Box my={4}>
            {children}
          </Box>  
        </Container>
        <Copyright />
      </main>
    </div>
  )

  const Copyright = () => {
    return (
      <Typography variant="body2" color="textSecondary" align="center">
        {'Copyright © '}
        <a href="https://stash-app.com">Stash </a>
        {' '}
        {new Date().getFullYear()}
        {'.'}
      </Typography>
    );
  }

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <Provider store={store}>
        <Router>
          <Admin path="admin">
            <TestPage path="test" />
            <Login path="login"/>
          
          </Admin>
          <Landing path="/">
            <StashCardPage path="app/stashcards/:id" />
            <VendorPage path="app/vendors/:id" />
            <GroupPage path="app/groups/:id" />
            <MagicLinkPage path="ios/log-me-in/:code" />
            <HomePage path="/" />
          </Landing>
        </Router>
      </Provider>
    </ThemeProvider>
  );
}

export default App;
