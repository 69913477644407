import axios from 'axios';
import errors from '../modules/http-errors';

class DataService  {

  constructor() {
    this.endPointURL = '/';
  }

  getVendor(vendorId){
    return new Promise((resolve, reject) => {
      const vendorEndPointURL = this.endPointURL + `api/vendor/${vendorId}`;
      axios.get(vendorEndPointURL, {})
      .then(response => {
        if(response.data.errors){
          return reject(response.data.errors)
        }

        const vendor = response.data;
        if(!vendor) {
          reject(new errors.ResourceNotFound(`No Vendor with id: ${vendorId}`))
        }
        return resolve(vendor);
      })
      .catch(reject);
    });
  }

  getStashCard(stashCardId){
    return new Promise((resolve, reject) => {
      return reject(new errors.NotImplementedError(`Can't load StashCard ${stashCardId}`));
      axios.post(this.endPointURL, {
        query: `
        query FetchStashCard($stashCardId: Int) {
          stashcard(where: {id: {_eq: $stashCardId}}) {
            id
            vendor {
              address {
                city
                postalCode
                stateCode
                street
                street2
              }
              businessEmail
              businessPhone
              categories {
                category {
                  id
                  name
                }
              }
              companyName
              contactName
              id
              notes
            }
            stasher {
              firstName
              lastName
              id
            }
          }
        }
        `,
        variables:{
          stashCardId: stashCardId
        }
      })
      .then(response => {
        if(response.data.errors){
          return reject(response.data.errors)
        }

        const stashCards = response.data.data.stashcard;
        if(stashCards.length < 1) {
          reject(new errors.ResourceNotFound(`No StashCard with id: ${stashCardId}`))
        }
        return resolve(stashCards[0]);
      })
      .catch(reject);
    });
  }
  /// find categories that match the names passed in the categoryNames array
  getCategories(categoryNames){

    return new Promise((resolve, reject) => { 
      axios
        .post(this.endPointURL, {
          query: `
          query CategoryMatchingNames($categoryNames: [String!]){
            category(where: {name: {_in: $categoryNames}}) {
              name
              id
            }
          }`,
          variables:{
            categoryNames: categoryNames
          }
        })
        .then(response => {
          if(response.data.errors){
            return reject(response.data.errors)
          }
          //TODO: migrate response into local object structure
          const categories = response.data.data.category;
          return resolve(categories);
        })
        .catch(reject);
    });
  }

  saveCategories(categories) {
    return new Promise((resolve, reject) => { 
      axios
        .post(this.endPointURL, {
          query: `
          mutation add_categories($categories: [category_insert_input!]!){
            insert_category(objects: $categories) {
              returning {
                id
                name
                parentCategoryId
              }
            }
          }
          `,
          variables:{
            categories: categories
          }
        })
        .then(response => {
          if(response.data.errors){
            return reject(response.data.errors)
          }
          //TODO: migrate response into local object structure
          const categories = response.data.data.insert_category.returning;
          return resolve(categories);
        })
        .catch(reject);
    });
  }

  /// find all stashers
  getStashers(){

    return new Promise((resolve, reject) => { 
      axios
        .post(this.endPointURL, {
          query: `
          query AllStashers{
            stasher {
              id
              firstName
              lastName
              email
              mobilePhone
            }
          }`
        })
        .then(response => {
          if(response.data.errors){
            return reject(response.data.errors)
          }
          //TODO: migrate response into local object structure
          const stashers = response.data.data.stasher;
          return resolve(stashers);
        })
        .catch(reject);
    });
  }

  saveNewStashers(stashers) {
    return new Promise((resolve, reject) => { 
      axios
        .post(this.endPointURL, {
          query: `
            mutation add_stashers($stashers: [stasher_insert_input!]!){
              insert_stasher(objects: $stashers) {
                returning {
                  id
                  firstName
                  lastName
                  email
                  mobilePhone
                }
              }
            }
          `,
          variables:{
            stashers: stashers
          }
        })
        .then(response => {
          if(response.data.errors){
            return reject(response.data.errors)
          }
          //TODO: migrate response into local object structure
          const stashers = response.data.data.insert_stasher.returning;
          return resolve(stashers);
        })
        .catch(reject);
    });
  }

  updateStasher(stasher){
    const stasherId = stasher.id;
    //console.log(`${stasherId} ${stasher.firstName} ${stasher.lastName} email:${stasher.email}`)
    return new Promise((resolve, reject) => { 
      axios
        .post(this.endPointURL, {
          query: `
            mutation update_stasher($id: Int, $changes: stasher_set_input) {
              update_stasher(
                where: {id: {_eq: $id}},
                _set: $changes
              ) {
                affected_rows
                returning {
                  id
                  firstName
                  lastName
                  email
                  mobilePhone
                  profilePhotoUrl
                }
              }
            }
          `,
          variables:{
            id: stasherId,
            changes: stasher
          }
        })
        .then(response => {
          if(response.data.errors){
            return reject(response.data.errors[0])
          }
          //TODO: migrate response into local object structure
          const stasher = response.data.data.update_stasher.returning[0];
          return resolve(stasher);
        })
        .catch(reject);
    });
  }

  saveVendors(vendors) {
    return new Promise((resolve, reject) => { 
      /*
      GraphQL Structure ___
      
      companyName
      businessEmail
      businessPhone
      websiteUrl
      contactName
      notes (JSONB)
      originalStasherId
      address: {
        street
        street2
        city
        stateCode
        postalCode
      }
      categories {
        data[
          { categoryId }
        ]
      }
      stashcards {
        data [
          { stasherId }
        ]
      }
      */
      const gqlVendors = vendors.map(vendor => {
        if(!vendor.stasherIds){ 
          vendor.stasherIds = [];
        }
        const address = vendor.address ? { data: vendor.address } : null;
        return {
          companyName: vendor.companyName,
          businessEmail: vendor.businessEmail,
          businessPhone: vendor.businessPhone,
          websiteUrl: vendor.websiteUrl,
          contactName: vendor.contactName,
          address: address,
          notes: vendor.notes,
          originalStasherId: vendor.originalStasherId,
          categories: {
            data: vendor.categoryIds.map(catId => {
              return {categoryId: catId};
            })
          },
          stashcards: {
            data: vendor.stasherIds.map(stasherId => {
              return {stasherId: stasherId};
            })
          }
        }
      })
      axios
        .post(this.endPointURL, {
          query: `
            mutation add_vendors($vendors: [vendor_insert_input!]!){
              insert_vendor(objects: $vendors) {
                returning {
                  id
                  companyName
                  businessEmail
                  businessPhone
                  websiteUrl
                  contactName
                  notes 
                  originalStasherId 
                  address {
                    id
                    city
                    postalCode
                    stateCode
                    street
                    street2
                  }
                  categories {
                    category {
                      id
                      name
                    }
                  }
                  stashcards {
                    id
                    stasher {
                      id
                      firstName
                      lastName
                    }
                  }
                }
              }
            }
          `,
          variables:{
            vendors: gqlVendors
          }
        })
        .then(response => {
          if(response.data.errors){
            return reject(response.data.errors)
          }
          //TODO: migrate response into local object structure
          const vendors = response.data.data.insert_vendor.returning;
          return resolve(vendors);
        })
        .catch(reject);
    });
  }

  saveStashCards(stashCards) {
    return new Promise((resolve, reject) => { 
      axios
        .post(this.endPointURL, {
          query: `
            mutation add_stashcards($stashCards: [stashcard_insert_input!]!){
              insert_stashcard(objects: $stashCards) {
                returning {
                  id
                  stasherId
                  vendorId
                }
              }
            }
          `,
          variables:{
            stashCards: stashCards
          }
        })
        .then(response => {
          if(response.data.errors){
            return reject(response.data.errors)
          }
          //TODO: migrate response into local object structure
          const stashCards = response.data.data.insert_stashcard.returning;
          return resolve(stashCards);
        })
        .catch(reject);
    });
  }
}
          
export default DataService;



