import React, { Component } from "react";
import { connect } from "react-redux";

import { makeStyles, useTheme } from '@material-ui/core/styles';

import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';


const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
    marginTop: theme.spacing(3),
    overflowX: 'auto',
    justifyContent: 'center'
  }
}));

const mapStateToProps = state => {
  return { 
    currentUser: state.auth.currentUser
  };
};

function mapDispatchToProps(dispatch) {
  return {
    
  };
}

function HomePage(props) {
  const classes = useStyles();
  const theme = useTheme();

  return (
    <div style={{overflowX: 'auto', justifyContent: 'center'}}>
      <Paper className={classes.root}>
        <h1>Welcome to STASH App</h1>
        <Typography variant="body1" color="textPrimary" align="center">
        To learn more about STASH, please visit <a href="http://stash-app.com">stash-app.com</a>
        </Typography>
      </Paper>
    </div>
  );
  
}

export default connect(mapStateToProps, mapDispatchToProps)(HomePage);
